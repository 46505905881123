import * as Phaser from 'phaser';

import Preloader from './scenes/Preloader'
import Game from './scenes/Game'
import GameUI from './scenes/GameUI'

export default class Demo extends Phaser.Scene
{
            constructor ()
            {
                            super('demo');
                        }

            preload ()
            {
                //How can assets/phaser3-logo.png be loaded? Could it be something with the constructor?
                            this.load.image('logo', 'assets/phaser3-logo.png');
                            this.load.image('libs', 'assets/libs.png');
                            this.load.glsl('bundle', 'assets/plasma-bundle.glsl.js');
                //OPEN GL SHADING LANGUAGE - computer program that calcs approx levels of light, darkness and color during the rendering of a 3D scene.
                            this.load.glsl('stars', 'assets/starfields.glsl.js');
                            this.load.image('protagonist', './../public/enemies/lizard.png');
                        }

            create ()
            {
                            this.add.shader('RGB Shift Field', 0, 0, 800, 600).setOrigin(0);

                            this.add.shader('Plasma', 0, 412, 800, 172).setOrigin(0);

                            this.add.image(400, 300, 'libs');

                            // after you've preloaded an image, you'll place it via the Phaser create method
                            // youve got x, y axis and a screen width of course.. 
                            this.add.image(400, 400, 'protagonist');

                            const logo = this.add.image(600, 70, 'logo');

                            this.tweens.add({
                                                targets: logo,
                                                y: 350,
                                                duration: 1500,
                                                ease: 'Sine.inOut',
                                                yoyo: true,
                                                repeat: -1
                                            })
                        }
}

const MAX_SIZE_WIDTH_SCREEN = 1920
const MAX_SIZE_HEIGHT_SCREEN = 1080
const MIN_SIZE_WIDTH_SCREEN = 270
const MIN_SIZE_HEIGHT_SCREEN = 480
const SIZE_WIDTH_SCREEN = 540
const SIZE_HEIGHT_SCREEN = 960


const config = {
            type: Phaser.AUTO,
			scale: {
				mode: Phaser.Scale.RESIZE,
        		parent: 'game',
        		width: SIZE_WIDTH_SCREEN,
        		height: SIZE_HEIGHT_SCREEN,
        		min: {
            		width: MIN_SIZE_WIDTH_SCREEN,
            		height: MIN_SIZE_HEIGHT_SCREEN
        		},
        		max: {
            		width: MAX_SIZE_WIDTH_SCREEN,
            		height: MAX_SIZE_HEIGHT_SCREEN
        		}	
			},
            backgroundColor: '#125555',
            width: 800,
            height: 600,
            scene: [Demo]
            //scene: [Preloader, Game, GameUI, Demo]
};

const game = new Phaser.Game(config);
